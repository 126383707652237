import React from 'react';
import { Link, Paragraph, PlatformTab } from '../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <Paragraph>
        We have Uniform components for{' '}
        <Link href="/components/type/headline" isDesignCodeLink>
          headlines
        </Link>
        ,{' '}
        <Link href="/components/type/subhead" isDesignCodeLink>
          subheads
        </Link>
        ,{' '}
        <Link href="/components/type/text" isDesignCodeLink>
          text
        </Link>{' '}
        and{' '}
        <Link href="/components/type/item-title" isDesignCodeLink>
          item titles
        </Link>
        .
      </Paragraph>
    </PlatformTab>
  );
};

export default WebTab;
