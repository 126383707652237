import React from 'react';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../components';
import pageHeroData from '../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Typography"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Typography" />

      <PlatformTabs
        pageType="guidelines"
        platforms={{ web: <WebTab />, reactnative: <ReactNativeTab /> }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
